// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  umsUrl: "https://umsapinode.dev10.in/",
  gisUrl: "https://gps.dev10.in/",
  emUrl: "https://apiem.dev10.in/",
  user_token: 'TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=',
  wms_url: "https://admin.dev10.in/geoserver/",
  estUrl: 'https://estapi.dev10.in/estater/api/',
  geoestater: 'https://ged.gisworld.in/',
  emsUrl:`https://ems.dev10.in/`,
  epsUrl:`https://eps.dev10.in/`,
  api_key: '31107fe38cd8e7034b80096c0d93fd3d'
};
