import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }
  notify(message: string,type: string="info", duration: number = 10000, action: string = "Dismiss") {
    var alertClass= "custom-info";
    switch (type) {
      case "warn":
        alertClass = "custom-warn";
        break;
      case "error":
        alertClass = "custom-error";
        break;
      case "success":
        alertClass = "custom-success";
        break;
      case "info":
        alertClass = "custom-info";
        break;

    }
    let sb = this.snackBar.open(message, action, {
      duration: duration,
      panelClass: [alertClass]



    });
    sb.onAction().subscribe(() => {
      sb.dismiss();
    });
    

  }
}
