import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CommonService {

  constructor(private api: ApiService) {
    //this.checkResourceAccess('attributegroups','GET',false);

  }

// this function check object .
  isEmpty(data) {
    if (typeof (data) == 'undefined' || data === null || data==null ||  data=='null' || data === '' || data === "" || data == '{}' || data === ' ' || data === " ") {
     return true;
    }else if(typeof(data) == 'object'){   
     for(var key in data) {
         if(data.hasOwnProperty(key)){
           var dt = {}
           dt= data[key];
           if(typeof(dt) == 'object'){
             for(var key_2 in dt) {
               if(dt[key_2]===undefined || dt[key_2]===null || dt[key_2]==="" || dt[key_2]===" " || dt[key_2]===' '){
                 return true;
               }else{
                   return false;
               }
             }
           }else{
             if(data[key]===undefined ||data[key]===null || data[key]==="" || data[key]===" " || data[key]===' '){
               return true;
             }else{
                 return false;
             }
           }
         }
     }
     return true;
    }
    return false;
    };
  // return index of object from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }
  // move array element by changing it's index
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    arrayObj.forEach(obj => {
      if (obj[key] == val && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }

  removeDuplicateKey(arr: Array<any>) { //get unique array
    let uniqueArray = [];
    arr.forEach(function (element) {
      if (uniqueArray.indexOf(element) === -1) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }
  // remove duplicate object key - val
  uniqueObject(key: any, arr: Array<Object>) { //get unique array
    let _this = this;
    let uniqueArray = [];
    arr.forEach(function (element: Object) {
      if (_this.getArrayindex(key, element[key], uniqueArray) == null) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }

  getArrayValueNull(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach(obj => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) { // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value) {
    if(value){
      value = new Date(value);
      return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
    }
    return '';
  }

  getAddressText(address_obj: any) {
    let address = '';
    if (address_obj['neigh_name']) address += '' + address_obj.neigh_name + ", ";
    if (address_obj['block_name']) address += 'Block ' + address_obj.block_name + ", ";
    if (address_obj['street_name']) address += '' + address_obj.street_name + ", ";
    if (address_obj['lane_name']) address += '' + address_obj.lane_name + ", ";
    if (address_obj['parcel_no']) address += 'Parcel ' + address_obj.parcel_no + ", ";
    if (address_obj['house_no']) address += 'House No ' + address_obj.house_no + ", ";
    if (address_obj['building_name']) address += ' ' + address_obj.building_name;
    if (address) {
      return address;
    } else {
      return 'Invalid address';
    }
  }

  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj, k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  getErrorMessage(err) {
    let msg = err.error;
    return msg.message;
  }


  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false
    }
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    if (resources && resources.length) {
      resources.forEach(element => {
        if (element.resource_name == name && element.methods) {
          if (element.methods.indexOf('GET') != -1) {
            resource.GET = true;
          }
          if (element.methods.indexOf('POST') != -1) {
            resource.POST = true;
          }
          if (element.methods.indexOf('PATCH') != -1) {
            resource.PATCH = true;
          }
          if (element.methods.indexOf('DELETE') != -1) {
            resource.DELETE = true;
          }
        }
      });
    }
    return resource;

  }



  assignmentSortOrder(data) {
    var newdata = [];
    let label = '';
    data.forEach(element => {
      if (element.assignment_label[this.api.language] == null) {
        label = element.assignment_name
      } else {
        label = element.assignment_label[this.api.language];
      }
      element.name = label;
      newdata.push(element);
    });
    newdata.sort(this.GetSortOrder("name"));
    return newdata;
  }
  neighborhoodSortOrder(data) {
    var newdata = [];
    let neigh_keys;
    data.forEach(element => {
      if (element.name) newdata.push(element);
    });
    newdata.sort(this.GetSortOrder("name"));
    return newdata;
  }
  //Comparer Function  
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
        return 1;
      } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
        return -1;
      }
      return 0;
    }
  }

  formatDateTime(value: any) {
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0) + " " + value.getHours().toString().padStart(2, 0) + ":" + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
  }

  formatDateUTC(dt: any, getTime: boolean = false) {
    let value;
    value = new Date(dt + ' UTC');
    if (getTime) {
      return  value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'}) + " " + value.getHours().toString().padStart(2, 0) + ":"
        + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
    } else {
      return (
        value.toLocaleDateString('en-GB', {day: 'numeric',month: 'numeric',year: 'numeric'})
      );
    }
  }
}