import { Component } from '@angular/core';
import { MediaChange, MediaObserver} from '@angular/flex-layout';
import {  } from "@angular/flex-layout";
import { Subscription } from 'rxjs';
import { ApiService } from './services/api.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dc';
  public mobile:boolean;
  public watcher:Subscription;
  constructor(
    private media: MediaObserver,
    private api:ApiService
  ){
    this.watcher = media.asObservable().subscribe((change:MediaChange[]) => {
      change.forEach(screen=>{
        if (screen.mqAlias == 'sm') {
          this.mobile = true;
          
        }
        if (screen.mqAlias == 'xs') {
          this.mobile = true;
        }
      })
     api.mobile=this.mobile;
    });
  }

}
