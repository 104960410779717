import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject,Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  public neighPanSubject=new Subject<void>();
  public blockPanSubject=new Subject<void>();
  public parcelPanSubject=new Subject<void>();
  public parcelList=new Subject<any>();
  public attributeList=new BehaviorSubject<any>("");
  public masterList=new BehaviorSubject<any>("");
  public parcelAddress=new BehaviorSubject<any>("");
  public attrgroupList= new BehaviorSubject<any>("");
  public showAddressbar= new BehaviorSubject<boolean>(true);
  public selectedAttributeGroup= new BehaviorSubject<any>('');
  public loader=new BehaviorSubject<boolean>(false);
  public paciNumber= new BehaviorSubject<any>("");
  public selectedParcel = new BehaviorSubject<any>("");
  public afterImageVideoUpload=new BehaviorSubject<any>("");
  public selectedAssignment = new Subject<any>();

  constructor() { }


  sendNeighPanEvent(){
  this.neighPanSubject.next();
  // this.neighPanSubject.complete();
  }
  getNeighPanEvent():Observable<any>{
    return this.neighPanSubject.asObservable();
  }


  sendBlockPanEvent(){
  this.blockPanSubject.next();
  // this.blockPanSubject.complete();
  }
  getBlockPanEvent():Observable<any>{
  return this.blockPanSubject.asObservable();
  }

  sendParcelPanEvent(){
  this.parcelPanSubject.next();
  // this.parcelPanSubject.complete();
  }

  getParcelPanEvent():Observable<any>{
    return this.parcelPanSubject.asObservable();
  }

  getParcelList(){
  return this.parcelList.asObservable();
  }

}
