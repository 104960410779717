import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
const policyRouterOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled'
}
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    loadChildren: () => import('./public/login/login.module').then(m => m.LoginModule),
    // loadChildren:  './public/login/login.module#LoginModule',
    // canActivate: [LoginGuard]
  },
  {
    path: 'city',
    loadChildren: () => import('./public/city-select/city-select.module').then(m => m.CitySelectModule),
    // loadChildren: './public/city-select/city-select.module#CitySelectModule',
    // canActivate: [CityGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule),
    // loadChildren: './private/private.module#PrivateModule',
    // canActivate: [CityGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,policyRouterOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
