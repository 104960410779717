import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NotificationService } from './services/notification.service';
import { ApiService } from "./services/api.service";
import { CommonService } from './services/common.service';
import { MatDatepickerModule} from '@angular/material/datepicker';
import {  } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatChipsModule } from "@angular/material/chips";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SubjectService } from "./services/subject.service";
import { NgxLoadingModule,ngxLoadingAnimationTypes } from "ngx-loading";
import { InterceptorService } from './services/interceptor.service';
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
}
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    // providers?: Provider[];
  }
}
@NgModule({
  declarations: [AppComponent],
imports:[
    AppRoutingModule,
    FlexLayoutModule,
    RouterModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSnackBarModule,
    RecaptchaV3Module,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    RecaptchaModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      backdropBorderRadius: '4px',
      primaryColour: '#f69230',
      secondaryColour: '#16ceed',
      tertiaryColour: '#23cb6b',
      fullScreenBackdrop: false
    })
  ],
  providers: [ApiService, NotificationService, CommonService,SubjectService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky' },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
