import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
@Injectable()
export class InterceptorService {

  constructor(private route: Router,  private notify: NotificationService,) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if ([403].includes(event?.body?.status) && localStorage.getItem('user_token')) {
                    localStorage.clear();
                    this.route.navigateByUrl('/login');
                }
            }
            return event;
        }),
        catchError((error: HttpErrorResponse) => {
            if([403].includes(error.status) && localStorage.getItem('user_token')){
                localStorage.clear();
                // this.notify.notify(error.error.message,'error')
                this.route.navigateByUrl('/login');
            }
            return throwError(() => error)
        })
    );
}
}
