import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public httpOptionsUms;
  public httpOptions;
  public status;
  public device: any;
  public mobile: boolean;
  public ip: any;
  public user_id: any;
  public selected_assignment:any;
  // public user_name: string;
  public user_token: string = '';
  public encryptionKey: string = '';
  public place_id: any = '';
  public city_code = '';
  public app_id = '7';
  public language: number = 1;
  public nodeUrl = null;
  public syncUrl = null;
  public umsUrl = null;
  public estUrl = null;
  public gisUrl = null;
  public emsUrl = null;
  public epsUrl = null;
  public emUrl = '';
  public wms_url = null;
  public language_list = [];
  public allowResources = [];
  public apiemUrl = null;
  public layerUrl: string = '';
  public geoestater: string = '';
  public assignmentAttributes: any = [];
  public api_key: string = '';
  constructor(private http: HttpClient, private route: Router,
  ) {
    this.setApiUrl();
    if (localStorage.getItem('language_id')) {
      this.language = +localStorage.getItem('language_id');
    }
    if (localStorage.getItem('selected_assignment')) this.selected_assignment=JSON.parse(localStorage.getItem('selected_assignment'));
    if (localStorage.getItem('place_id')) this.place_id = localStorage.getItem('place_id');
    if (localStorage.getItem('app_id')) this.app_id = localStorage.getItem('app_id');
    if (localStorage.getItem('city_code')) this.city_code = localStorage.getItem('city_code');
    if (localStorage.getItem('user_id')) this.user_id = localStorage.getItem('user_id');
    if (localStorage.getItem('user_token')) this.user_token = localStorage.getItem('user_token');
    // this.user_name = localStorage.getItem('user_name');
    this.getLanguageList();
    // if (this.user_id) {

    // }

  }

  setApiUrl() {
    this.estUrl = environment.estUrl;
    this.gisUrl = environment.gisUrl;
    this.umsUrl = environment.umsUrl;
    this.emUrl = environment.emUrl;
    this.emsUrl = environment.emsUrl;
    this.epsUrl = environment.epsUrl
    this.geoestater = environment.geoestater;
    this.api_key = environment.api_key;
    this.user_token = (this.user_token == '') ? environment.user_token : this.user_token;
  }

  setUploadHeaderUms(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    // headers.append('Content-Type', content_type);
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    headers = headers.append('city-id', String(this.place_id));
    headers = headers.append('USER-CITY', String(this.place_id));
    headers = headers.append('city-code', this.city_code ? String(this.city_code):'');

    headers = headers.append('user-token', this.user_token);
    headers = headers.append('app-id', this.app_id);
    headers = headers.append('user-lang', 'en-us');
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', String(this.ip));
    } else {
      headers = headers.append('user-ip', '::');
    }
    this.httpOptions = { headers: headers };

    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }
  // setting header for UMS
  setHeader(content_type = 'application/json') {
    //let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    headers = headers.append('app-id', this.app_id);
    //headers = headers.append('User-Device', dInfo);
    headers = headers.append('user-lang', 'en-us');
    headers = headers.append('city-id', String(this.place_id))
    // if (localStorage.getItem('ip')) {
    //   headers = headers.append('user-ip', this.ip);
    // } else {
    //   headers = headers.append('user-ip', '::');
    // }
    headers = headers.append('user-token', this.user_token);
    this.httpOptions = { headers: headers };
  }
  //ESTATER
  setEstHeader(content_type = 'application/json', response_type = null) {
    let headers = new HttpHeaders();
    if (content_type != 'no-content') {
      headers = headers.append('Content-Type', content_type);
    }
    headers = headers.append('USER-CITY', String(this.place_id));
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', '' + this.language + '');
    // console.log(headers);
    headers = headers.append('API-KEY', this.api_key);
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }
  //GIS
  setGisHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', String(this.language));
    headers = headers.append('USER-CITY', String(this.place_id));
    headers = headers.append('USER-TOKEN', this.user_token);
    this.httpOptions = { headers: headers };
  }

  getEstData(url) {
    this.setEstHeader();
    return this.http.get(this.estUrl + url, this.httpOptions)
  }

  getGisData(url) {
    this.setGisHeader();
    return this.http.get(this.gisUrl + url, this.httpOptions)
  }

  // EMS
  getEmsData(url) {
    this.setEstHeader();
    return this.http.get(this.emsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  // this function for Eps start
  getEpsData(url) {
    this.setEstHeader();
    return this.http.get(this.epsUrl + url, this.httpOptions)
  }
  getUmsData(url) {
    this.setHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

  // send a POST request to the API to create a data object
  postUmsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  postEstData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setEstHeader();
    return this.http.post(this.estUrl + urlWithId, body, this.httpOptions);
  }

  postEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setEstHeader();
    return this.http.post(this.epsUrl + url, body, this.httpOptions);
  }

  patchEstData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setEstHeader();
    return this.http.patch(this.estUrl + urlWithId, body, this.httpOptions);
  }

  patchEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setEstHeader();
    return this.http.patch(this.epsUrl + url, body, this.httpOptions);
  }

  // send a PATCH request to the API to update a data object
  patchUmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }

  patchEmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setEstHeader();
    return this.http.patch(this.emsUrl + urlWithId, body, this.httpOptions);
  }

  deleteUmsData(url) {
    this.setHeader();
    return this.http.delete(this.umsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  deleteEpsData(url) {
    this.setEstHeader();
    return this.http.delete(this.epsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

  postFileUms(url, body, response_type = null) {
    // if (body === null || body === undefined) {
    //   throw new Error('Required parameter body was null or undefined when calling Api.');
    // }
    // this.setUploadHeaderUms('application/json', response_type);
    // return this.http.post(this.estUrl + url, body, this.httpOptions);
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderUms('application/json', response_type);
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }


  // getting list of property type
  getLanguageList() {
    this.getUmsData('language')
      .subscribe(
        (res: any) => {
          
          if (res && res.data) {
            res.data.forEach(element => {
              if (element.status == 1) {
                this.language_list.push(element);
              }
            });
          }
        }
      );
  }


  // getting allow resources
  getAllowResources() {
    let body = `user/resource?place_id=${this.place_id}&user_id=${this.user_id}`
    this.getUmsData(body)
      .subscribe(
        (data: any) => {
          this.allowResources = data;
        }
      )
  }


  logout() {
    this.getUmsData('logout')
      .subscribe(data => {
        localStorage.clear();
        this.user_id = null;
        this.selected_assignment=null;
      });
    localStorage.clear();
    this.user_id = null;
    this.route.navigateByUrl('login');
  }
}
